import AnalyticsEventsCollectorInterface from "@lib/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { singleton } from "tsyringe";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import CommonSdk from "./common_sdk/CommonSdk";
import { SDK_READY } from "./constants";
import SdkEvent from "./events/SdkEvent";
import { SdkEventInterface } from "./events/SdkEventInterface";
import { SdkRegisteredModuleInterface } from "./SdkRegisteredModuleInterface";
import { SdkInitOptions } from "./types";

@singleton()
class Sdk {
    private readonly _emitter: EventTarget;

    private readonly _modules: Record<string, SdkRegisteredModuleInterface> =
        {};

    public readonly common?: CommonSdk;

    constructor(
        private readonly _initApp: (options: SdkInitOptions) => void,
        private readonly _eventsCollector: AnalyticsEventsCollectorInterface,
        private readonly _eventsFactory: SdkAnalyticsEventsFactory
    ) {
        this._emitter = new EventTarget();
    }

    public init(options: SdkInitOptions) {
        this._initApp(options);
    }

    public start() {
        if (!this.common) {
            throw new Error(
                "SDK cannot start without CommonSdk being registered"
            );
        }

        if (window && window.gw && !window.gw.sdk) {
            window.gw.sdk = this;
            document.dispatchEvent(new Event(SDK_READY));

            this._eventsCollector.pushEvent(
                this._eventsFactory.createSdkStarted()
            );
        }
    }

    public on(type: string, callback: (event: Event) => void) {
        this._eventsCollector.pushEvent(
            this._eventsFactory.createEventSubscribed(type)
        );
        this._emitter.addEventListener(type, callback);
    }

    public off(type: string, callback: (event: Event) => void) {
        this._eventsCollector.pushEvent(
            this._eventsFactory.createEventUnsubscribed(type)
        );
        this._emitter.removeEventListener(type, callback);
    }

    public dispatch(event: SdkEventInterface) {
        this._eventsCollector.pushEvent(
            this._eventsFactory.createEventDispatched(event.type)
        );
        this._emitter.dispatchEvent(
            new SdkEvent(event.type, { detail: event })
        );
    }

    public registerModule(
        moduleName: string,
        module: SdkRegisteredModuleInterface
    ) {
        module.register(this);
        this._modules[moduleName] = module;

        this._eventsCollector.pushEvent(
            this._eventsFactory.createSdkModuleRegistered(moduleName)
        );
    }
}

export default Sdk;
