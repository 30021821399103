class SdkEvent extends Event {
    public readonly detail: Record<string, unknown>;

    constructor(type: string, detail: Record<string, unknown>) {
        super(type);
        this.detail = detail;
    }
}

export default SdkEvent;
