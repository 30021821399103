import { Infer, object, string, type } from "superstruct";

import RewardsBrandingSchema from "./RewardsBrandingSchema";
import WishlistBrandingSchema from "./WishlistBrandingSchema";

export const BrandingOptionsSchema = object({
    login: object({
        brand: string(),
        text: string(),
        background: string(),
    }),
    reviews: object({
        reviews_main_color: string(),
        reviews_text_color: string(),
        reviews_stars_color: string(),
    }),
    rewards: RewardsBrandingSchema,
    wishlist: WishlistBrandingSchema,
});

export const AdditionalInfoBrandingSchema = type({
    GW_BRANDING_OPTIONS: BrandingOptionsSchema,
});

export type AdditionalInfoBranding = Infer<typeof AdditionalInfoBrandingSchema>;
