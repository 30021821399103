import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    map,
    switchMap,
} from "rxjs";

import { ProductIdManagerInterface } from "./ProductIdManagerInterface";
import { ProductIdProviderInterface } from "./ProductIdProviderInterface";

export class ProductIdManager implements ProductIdManagerInterface {
    providers$ = new BehaviorSubject<ProductIdProviderInterface[]>([]);
    productId$ = new BehaviorSubject<number | null>(null);

    constructor() {
        this.providers$
            .pipe(
                map((providers) =>
                    providers.map((provider) => provider.selectProductId())
                ),
                switchMap((arr) => combineLatest(arr)),
                map((values) => this._getLastValidProductId(values)),
                distinctUntilChanged()
            )
            .subscribe(this.productId$);
    }

    private _getLastValidProductId(values: (number | null)[]) {
        if (!values.length) return null;

        let idx = values.length - 1;

        while (idx >= 0) {
            if (values[idx]) return values[idx];
            else idx--;
        }

        return null;
    }

    public registerProvider(provider: ProductIdProviderInterface): void {
        this.providers$.next([...this.providers$.getValue(), provider]);
    }
}
